import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
    {
        name: 'Início',
        url: '/home',
        icon: 'icon-account-logout'
    },
    {
        title: true,
        name: 'Imóveis'
    },
    {
        name: 'Captações',
        url: '/imoveis-captacoes',
        icon: '<i class="fa fa-plus-square-o"></i>'
    },
    {
        name: 'Baixas',
        url: '/imoveis-baixas',
        icon: '<i class="fa fa-minus-square-o"></i>'
    },
    {
        name: 'Reservas',
        url: '/imoveis-reservas',
        icon: '<i class="fa fa-minus-square-o"></i>'
    },
    {
        name: 'Saídas de Chaves',
        url: '/imoveis-chaves',
        icon: '<i class="fa fa-minus-square-o"></i>'
    },
    {
        title: true,
        name: 'Contratos'
    },
    {
        name: 'Novos',
        url: '/contratos-novos',
        icon: '<i class="fa fa-minus-square-o"></i>'
    },
    {
        name: 'Renovações',
        url: '/contratos-renovados',
        icon: '<i class="fa fa-minus-square-o"></i>'
    },
    {
        name: 'Reajustes',
        url: '/contratos-reajustados',
        icon: '<i class="fa fa-plus-square-o"></i>'
    },
    {
        divider: true
    }
];
