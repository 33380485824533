import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'Home'
        },
        children: [
            {
                path: 'home',
                loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'imoveis-captacoes',
                loadChildren: () => import('./views/captacoes/captacoes.module').then(m => m.CaptacoesModule)
            },
            {
                path: 'imoveis-baixas',
                loadChildren: () => import('./views/baixas/baixas.module').then(m => m.BaixasModule)
            },
            {
                path: 'imoveis-reservas',
                loadChildren: () => import('./views/reservas/reservas.module').then(m => m.ReservasModule)
            },
            {
                path: 'imoveis-chaves',
                loadChildren: () => import('./views/chaves/chaves.module').then(m => m.ChavesModule)
            },
            {
                path: 'contratos-novos',
                loadChildren: () => import('./views/contratos/novos.module').then(m => m.NovosModule)
            },
            {
                path: 'contratos-renovados',
                loadChildren: () => import('./views/contratos/renovados.module').then(m => m.RenovadosModule)
            },
            {
                path: 'contratos-reajustados',
                loadChildren: () => import('./views/contratos/reajustes.module').then(m => m.ReajustesModule)
            }
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})

export class AppRoutingModule {}
